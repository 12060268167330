import styled from 'styled-components';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { COLOR_BLUM, COLOR_LILA_LIGHT } from '../styles/variables';
import { Icon } from '../components/Icon';

const Wrapper = styled.div`
    background: ${COLOR_LILA_LIGHT};
    border-radius: 10px;
    padding: 10px 10px 10px 33px;
    position: relative;
    margin-bottom: 10px;
    color: ${COLOR_BLUM};
`;

const IconWrapper = styled.div`
    position: absolute;
    left: 10px;
    top: 12px;
`;

interface IInfoBoxProps {
    text: string;
}

const InfoBox = (props: IInfoBoxProps) => (
    <Wrapper>
        <IconWrapper>
            <Icon
                icon={faInfoCircle}
                color={COLOR_BLUM}
                style={{ width: '15px' }}
            />
        </IconWrapper>
        <p>{props.text}</p>
    </Wrapper>
);

export default InfoBox;
