import { ReactNode } from 'react';
import styled from 'styled-components';
import {
    COLOR_LIGHT_BG,
    LIGHT_GRADIENT,
    PAGEWIDTH_MAX,
} from '../styles/variables';

const Background = styled.div`
    /* The outermost div under the bar, wrapping the page content */
    background: ${COLOR_LIGHT_BG} ${LIGHT_GRADIENT};
    display: flex;
    justify-content: center;
`;

const PageContentWrapper = styled.div`
    /* The page content wrapper*/
    display: flex;
    width: 100%;
    border-radius: 0 0 10px 10px;
    background: ${COLOR_LIGHT_BG};

    @media (min-width: ${PAGEWIDTH_MAX}px) {
        width: ${PAGEWIDTH_MAX}px;
    }
`;

interface IPurpleBackgroundProps {
    children: ReactNode;
    fadeOff?: boolean;
}

const PurpleBackground = (props: IPurpleBackgroundProps) => (
    <Background>
        <PageContentWrapper>{props.children}</PageContentWrapper>
    </Background>
);

export default PurpleBackground;
