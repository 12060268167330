import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import GlobalStyles from './styles/global';
import SalesPage from './pages/sales-page';
import ReturnPage from './pages/return-page';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/pay/:publicPath" element={<SalesPage />} />
                <Route path="/e-payment-return" element={<ReturnPage />} />
            </Routes>
            <GlobalStyles />
        </BrowserRouter>
    );
}

export default App;
