import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { UiLanguage } from '../utils/i18n';
import { COLOR_LILA } from '../styles/variables';
import { trans, changeLanguage } from '../utils';

const LanguageSection = styled.div`
    button {
        font-weight: bold;
        padding: 0px 8px;

        &:hover,
        &.selected {
            text-decoration: underline;
        }

        &:focus {
            border-bottom: 1px solid ${COLOR_LILA};
        }
    }
    button:not(:first-child) {
        border-left: 2px solid ${COLOR_LILA};
    }
    button {
        color: ${COLOR_LILA};
        font-size: 16px;
    }
`;

interface ILanguageChangeProps {
    language?: UiLanguage;
    style?: CSSProperties;
}

const LanguageOptions = (props: ILanguageChangeProps) => (
    <LanguageSection style={props.style}>
        <button
            className={props.language === 'fi' ? 'selected' : ''}
            aria-label={trans('menu.lang-change-fi')}
            onClick={() => changeLanguage('fi')}
        >
            FI
        </button>
        <button
            className={props.language === 'en' ? 'selected' : ''}
            aria-label={trans('menu.lang-change-en')}
            onClick={() => changeLanguage('en')}
        >
            EN
        </button>
    </LanguageSection>
);

export default LanguageOptions;
