import React, { ReactNode } from 'react';
import styled from 'styled-components';
import {
    COLOR_BLACKWATER,
    COLOR_DARK_GRAY,
    COLOR_GRAYS_ANATOMY,
    COLOR_IMPORTANT,
    COLOR_LILA,
    FOCUS_COLOR,
} from '../../styles/variables';
import { trans } from '../../utils';
import { MobileRowButton } from '../Buttons';
import { PSmall } from '../textElements';

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
        font-weight: bold;
        text-transform: uppercase;
    }
`;

interface IHelpBoxProps {
    $dark?: boolean;
}

const HelpBox = styled.div`
    background-color: ${(props: IHelpBoxProps) => (props.$dark ? COLOR_LILA : COLOR_GRAYS_ANATOMY)};
    border-radius: 4px;
    padding: 15px 10px;
    margin: 10px 0;

    div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
    }

    p {
        color: ${(props: IHelpBoxProps) => (props.$dark ? COLOR_BLACKWATER : COLOR_DARK_GRAY)};
        font-size: 12px;
        line-height: 15px;
    }
    p ~ p {
        margin-top: 10px;
    }
    button,
    a {
        font-size: 13px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;

        &:focus {
            border-bottom: 1px solid ${FOCUS_COLOR};
            outline: none;
        }
    }
`;

interface IProps {
    labelComponent: ReactNode;
    dark?: boolean;
    helpLink?: string;
    helpTexts: string[];
}

export const HelpLabel = (props: IProps) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Wrapper>
                {props.labelComponent}

                {!open && (
                    <MobileRowButton
                        color={COLOR_IMPORTANT}
                        onClick={() => {
                            setOpen(true);
                        }}
                        type="button"
                    >
                        {trans('general.help')}
                    </MobileRowButton>
                )}
            </Wrapper>

            {open && (
                <HelpBox className="helpbox" $dark={props.dark}>
                    {props.helpTexts.map((h, index) => (
                        <PSmall key={`help-${index}`} color="white">
                            {trans(h)}
                        </PSmall>
                    ))}
                    <div>
                        {props.helpLink ? (
                            <a
                                href={props.helpLink}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {trans('general.help-more')}
                            </a>
                        ) : (
                            <span />
                        )}
                        <MobileRowButton
                            color={COLOR_IMPORTANT}
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            {trans('menu.close')}
                        </MobileRowButton>
                    </div>
                </HelpBox>
            )}
        </>
    );
};
