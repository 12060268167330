import React, {
    AnchorHTMLAttributes,
    ButtonHTMLAttributes,
    CSSProperties,
} from 'react';
import { Link as ReactRouterDomLink, LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
    COLOR_BLACKWATER,
    COLOR_BLUM,
    COLOR_BLUMER,
    COLOR_IMPORTANT,
    COLOR_IMPORTANT_DARKER,
    COLOR_LILA,
    COLOR_STATUS_DONE,
    COLOR_STATUS_WAITING,
    COLOR_WHITE_WALKER,
    FOCUS_COLOR,
    LILA_GRADIENT,
    SCREEN_M,
} from '../styles/variables';

/*
    The button styles defined in the style guide
    applied for both buttons and links.
*/

export const BaseButtonStyles = css`
    &&& {
        border-radius: 100px;
        font-size: 14px;
        font-weight: 600;
        height: 32px;
        letter-spacing: -0.5px;
        line-height: 28px;
        min-width: 110px;
        padding: 0 32px;
        text-align: center;
        text-transform: none;
        user-select: none;
        vertical-align: middle;
        white-space: nowrap;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    &&:disabled {
        cursor: default;
        opacity: 0.3;
    }

    && svg {
        font-size: 15px;
        height: 15px;
        width: 15px;
    }
`;

const EezyButtonStyles = css`
    && {
        &.dark {
            color: ${COLOR_WHITE_WALKER};

            &.purple {
                color: ${COLOR_WHITE_WALKER};
                background-color: ${COLOR_BLUM};
                border: none;
                &.border {
                    border: 1px solid ${COLOR_WHITE_WALKER};
                }
                &&:focus,
                &&:hover {
                    background-color: ${COLOR_BLUMER};
                }
            }
            &.black {
                background-color: ${COLOR_BLACKWATER};
                border: none;
            }
            &.green {
                background-color: ${COLOR_STATUS_DONE};
                border: none;
            }
            &.orange {
                background-color: ${COLOR_STATUS_WAITING};
                color: ${COLOR_WHITE_WALKER};
                &&:focus {
                    box-shadow: 0 0 10px 0 ${COLOR_WHITE_WALKER};
                }
                &:focus svg {
                    color: ${COLOR_WHITE_WALKER};
                }
            }
            &.important {
                background-color: ${COLOR_IMPORTANT};
                &&:focus,
                &&:hover {
                    background-color: ${COLOR_IMPORTANT_DARKER};
                }
            }
        }
        &.light {
            &.purple {
                background-color: ${COLOR_WHITE_WALKER};
                border: 1px solid ${COLOR_BLUM};
                color: ${COLOR_BLUM};
                &&:focus,
                &&:hover {
                    color: ${COLOR_BLUMER};
                }
            }
            &.black {
                background-color: transparent;
                border: 1px solid ${COLOR_BLACKWATER};
                color: ${COLOR_BLACKWATER};
            }
            &.green {
                background-color: ${COLOR_WHITE_WALKER};
                border: 1px solid ${COLOR_STATUS_DONE};
                color: ${COLOR_STATUS_DONE};
            }
            &.orange {
                background-color: ${COLOR_WHITE_WALKER};
                color: ${COLOR_STATUS_WAITING};
                &&:focus {
                    box-shadow: 0 0 10px 0 ${COLOR_WHITE_WALKER};
                }
                &:focus svg {
                    color: ${COLOR_WHITE_WALKER};
                }
            }
            &.important {
                background-color: ${COLOR_WHITE_WALKER};
                border: 1px solid ${COLOR_BLUM};
                color: ${COLOR_IMPORTANT};
                &&:focus,
                &&:hover {
                    color: ${COLOR_IMPORTANT_DARKER};
                }
            }
        }

        &.pink-gradient {
            background: ${LILA_GRADIENT};
            border: 1px solid ${COLOR_LILA};
            color: ${COLOR_BLUM};
            &:focus svg {
                color: ${COLOR_BLUM};
            }
            @media (max-width: ${SCREEN_M}px) {
                background-color: ${COLOR_LILA};
            }
        }

        &.full {
            min-height: 40px;
            padding: 0 20px;
            width: 100%;
        }
        &.icon:not(.icon-right) {
            svg {
                margin-right: 5px;
            }
        }
        &.icon-right {
            svg {
                float: right;
                margin-left: 5px;
                margin-top: 6px;
            }
        }
        &.square {
            border-radius: 4px;
        }
        &.transparent {
            background-color: transparent !important;
        }
        &.compact {
            padding: 0 10px;
        }
    }
    & + & {
        margin-left: 5px;
    }
`;

const StyledButton = styled.button`
    ${BaseButtonStyles};
    ${EezyButtonStyles};
`;

const StyledRouterLink = styled(ReactRouterDomLink)`
    ${BaseButtonStyles};
    ${EezyButtonStyles};
    &&& {
        line-height: 32px;
        text-decoration: none;
    }
    &:focus {
        outline: none;
    }
`;

const StyledLink = styled.a`
    ${BaseButtonStyles};
    ${EezyButtonStyles};
    display: inline-block;
    text-decoration: none;

    &:focus {
        outline: none;
    }
`;

export type ButtonColor =
    | 'purple'
    | 'black'
    | 'green'
    | 'orange'
    | 'important'
    | 'pink-gradient';
export type IconAlignment = 'right' | 'left';

// General props for all buttons and links
interface IButtonProps {
    border?: boolean;
    className?: string;
    color: ButtonColor;
    dark?: boolean;
    fullWidth?: boolean;
    hasIcon?: boolean;
    iconAlignment?: IconAlignment;
    square?: boolean;
    style?: CSSProperties;
    textColor?: string;
    transparent?: boolean;
    compact?: boolean;
    width?: number;
}

const getButtonProps = (props: IButtonProps) => {
    const classNames =
        props.color +
        (props.border ? ' border ' : '') +
        (props.dark ? ' dark ' : ' light ') +
        (props.fullWidth ? 'full ' : '') +
        (props.className || '') +
        (props.hasIcon ? ' icon ' : ' ') +
        (props.transparent ? ' transparent ' : '') +
        (props.compact ? ' compact ' : '') +
        (props.iconAlignment === 'right' ? ' icon-right' : '') +
        (props.square ? ' square' : '');

    const styles: CSSProperties = { ...props.style };
    if (props.width) {
        styles.minWidth = props.width;
    }
    if (props.textColor) {
        styles.color = props.textColor;
    }
    return {
        className: classNames,
        style: styles,
    };
};

type EezyButtonProps = IButtonProps & ButtonHTMLAttributes<HTMLButtonElement>;

// HTML button
export const EezyButton = React.forwardRef(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (props: EezyButtonProps, ref?: React.Ref<any>) => {
        const { ...restProps } = props;
        return (
            <StyledButton
                ref={ref}
                {...restProps}
                {...getButtonProps(props)}
                type="button"
            >
                {props.children}
            </StyledButton>
        );
    },
);

type EezyRouterLinkProps = IButtonProps & LinkProps;

// Anchor element to route to in-app links
export const EezyRouterLink = (props: EezyRouterLinkProps) => {
    const { ...restProps } = props;
    return (
        <StyledRouterLink {...restProps} {...getButtonProps(props)}>
            {props.children}
        </StyledRouterLink>
    );
};

type ILinkProps = IButtonProps & AnchorHTMLAttributes<HTMLAnchorElement>;

// Anchor element to route to external links
export const EezyLink = (props: ILinkProps) => {
    const { ...restProps } = props;
    return (
        <StyledLink {...restProps} {...getButtonProps(props)}>
            {props.children}
        </StyledLink>
    );
};

// Buttons looking like links
// TODO refactor only 1 button looking like a link

export const MobileRowButton = styled.button`
    color: ${(props: { color?: string }) =>
        props.color ? props.color : COLOR_BLUM};
    display: block;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.15px;
    line-height: 28px;
    text-align: left;

    &:focus {
        border-bottom: 1px solid ${FOCUS_COLOR};
    }

    &&:disabled {
        cursor: default;
    }
`;

export const InlineButtonLink = styled.button`
    color: ${(props: { color?: string }) =>
        props.color ? props.color : COLOR_BLUM};
    display: inline;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    text-align: left;
    text-decoration: underline;
`;
