export function env(variableName: string, defaultValue?: string): string {
    const value = process.env[variableName];
    if (value === undefined) {
        if (defaultValue === undefined) {
            throw new Error(`Missing environment variable ${variableName}`);
        }
        return defaultValue;
    }
    return value;
}
