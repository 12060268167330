import Bar, { BarContent } from './Bar';
import LanguageOptions from './LanguageOptions';

const Header = () => (
    <Bar>
        <BarContent>
            <LanguageOptions />
        </BarContent>
    </Bar>
);

export default Header;
