import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Modal, ModalContent } from '../../components/Modal';

interface ITermsModalProps {
    handleModalClose: () => void;
    isOpen: boolean;
}

const Heading = styled.div`
    font-weight: 600;
    margin-bottom: 20px;
`;

const Paragraph = styled.div`
    margin-bottom: 16px;
`;

const ContactsWrapper = styled.ul`
    margin-bottom: 16px;
`;

const TermsModal = (props: ITermsModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            small
            id="modal-download-salaries"
            isOpen={props.isOpen}
            onClose={props.handleModalClose}
            title={t('terms.modal-title')}
        >
            <ModalContent>
                <div>
                    <Heading>{t('terms.general.title')}</Heading>
                    <Paragraph>{t('terms.general.p1')}</Paragraph>
                    <Paragraph>{t('terms.general.p2')}</Paragraph>

                    <Heading>{t('terms.contact.title')}</Heading>
                    <ContactsWrapper>
                        <li>{t('terms.contact.line-1')}</li>
                        <li>{t('terms.contact.line-2')}</li>
                        <li>{t('terms.contact.line-3')}</li>
                        <li>{t('terms.contact.line-4')}</li>
                        <li>{t('terms.contact.line-5')}</li>
                        <li>
                            <a href={`mailto:${t('terms.contact.mail')}`}>
                                {t('terms.contact.mail')}
                            </a>
                        </li>
                    </ContactsWrapper>

                    <Heading>{t('terms.payment.title')}</Heading>
                    <Paragraph>{t('terms.payment.p1')}</Paragraph>
                    <Paragraph>{t('terms.payment.p2')}</Paragraph>

                    <Heading>{t('terms.payment-methods.title')}</Heading>
                    <Paragraph>{t('terms.payment-methods.p1')}</Paragraph>
                    <Paragraph>{t('terms.payment-methods.p2')}</Paragraph>
                    <Paragraph>
                        {t('terms.payment-methods.p3')}{' '}
                        <a
                            href="https://pivo.fi/kayttoehdot/pivon-kayttoehdot/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            https://pivo.fi/kayttoehdot/pivon-kayttoehdot/
                        </a>
                    </Paragraph>

                    <Heading>{t('terms.contact-visma-pay.title')}</Heading>
                    <ContactsWrapper>
                        <li>{t('terms.contact-visma-pay.line-1')}</li>
                        <li>
                            {t('terms.contact-visma-pay.line-2-label')}{' '}
                            <a
                                href={`mailto:${t(
                                    'terms.contact-visma-pay.line-2-mail',
                                )}`}
                            >
                                {t('terms.contact-visma-pay.line-2-mail')}
                            </a>
                        </li>
                        <li>
                            {t('terms.contact-visma-pay.line-3-label')}{' '}
                            <a
                                href={`tel:${t(
                                    'terms.contact-visma-pay.line-3-number',
                                )}`}
                            >
                                {t('terms.contact-visma-pay.line-3-number')}
                            </a>{' '}
                            {t('terms.contact-visma-pay.line-3-workdays')}
                        </li>
                        <li>{t('terms.contact-visma-pay.line-4')}</li>
                    </ContactsWrapper>

                    <Heading>{t('terms.complaints.title')}</Heading>
                    <Paragraph>{t('terms.complaints.p1')}</Paragraph>
                    <Paragraph>{t('terms.complaints.p2')}</Paragraph>
                    <Paragraph>{t('terms.complaints.p3')}</Paragraph>
                </div>
            </ModalContent>
        </Modal>
    );
};
export default TermsModal;
