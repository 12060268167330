import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
    COLOR_BLUM,
    COLOR_WHITE_WALKER,
    SCREEN_M,
    SCREEN_S,
    TOPBAR_HEIGHT,
} from '../../styles/variables';
import { config } from '../../config';
import Header from '../../components/Header';
import PurpleBackground from '../../components/PurpleBackground';
import successImg from '../../assets/img/success.svg';
import { isMobile } from '../../utils/common';
import { Toast } from '../../components/Toast';
import { Flex } from '../../components/Flex';
import LoadingSpinner from '../../components/Loading';
import { EezyButton } from '../../components/Buttons';
import errorImg from '../../assets/img/error.svg';
import { changeLanguage } from '../../utils';
import { UiLanguage } from '../../utils/i18n';

interface IPaymentNotifyResponse {
    email: string;
    language: UiLanguage;
    orderNumber: string;
    success: boolean;
    thirdPartyOrderId?: string;
    thirdPartyNotifySuccess?: boolean;
    thirdParty?: string;
    thirdPartyReturnUrl?: string;
}

interface IProps {}

const ContentWrapper = styled.div`
    width: 100%;
    display: block;
    padding-top: 40px;
    min-height: calc(100vh - ${TOPBAR_HEIGHT}px);
    @media (min-width: ${SCREEN_M}px) {
        min-height: calc(100vh - ${TOPBAR_HEIGHT}px);
    }
    @media (max-width: ${SCREEN_S}px) {
        display: flex;
        align-items: center;
    }
`;

const ReturnPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-items: center;
    padding: 130px;
    width: calc(100% - 40px);
    max-width: 644px;
    background: ${COLOR_WHITE_WALKER};
    border-radius: 10px;
    margin: 0 auto ${TOPBAR_HEIGHT}px auto;
    @media (max-width: ${SCREEN_S}px) {
        padding: 30px;
    }
`;

const ImportantText = styled.div`
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
    text-align: center;
`;

const SecondaryText = styled.div`
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
`;

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const redirectToCampwire = (
    campwireReturnUrl: string,
    campwireOrderId: string,
    success: boolean,
) => {
    setTimeout(() => {
        window.location.href = `${campwireReturnUrl}?order_id=${campwireOrderId}&status=${
            success ? 'success' : 'failed'
        }`;
    }, 1000);
};

const ReturnPage: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const query = useQuery();
    const returnCode = query.get('RETURN_CODE');

    const { search } = useLocation();

    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [paymentSuccess, setPaymentSuccess] = useState<boolean>(
        returnCode === '0',
    );
    const [orderNumber, setOrderNumber] = useState<string>();
    const [email, setEmail] = useState<string>();

    const [thirdParty, setThirdParty] = useState<string>();
    const [thirdPartyOrderId, setThirdPartyOrderId] = useState<string>();
    const [thirdPartyReturnUrl, setThirdPartyReturnUrl] = useState<string>();

    useEffect(() => {
        axios
            .get(`${config.EEZYPAY_API_HOST}/v1/pay/e-payment-notify${search}`)
            .then((response: { data: IPaymentNotifyResponse }) => {
                if (
                    response?.data?.thirdParty &&
                    response?.data?.thirdPartyOrderId &&
                    response?.data?.success
                ) {
                    setThirdParty(response?.data?.thirdParty);
                    setThirdPartyOrderId(response?.data?.thirdPartyOrderId);
                    setThirdPartyReturnUrl(response?.data?.thirdPartyReturnUrl);
                    if (
                        response?.data?.thirdParty === 'campwire' &&
                        response?.data?.thirdPartyReturnUrl &&
                        response?.data?.thirdPartyOrderId
                    ) {
                        redirectToCampwire(
                            response.data.thirdPartyReturnUrl,
                            response.data.thirdPartyOrderId,
                            returnCode === '0',
                        );
                    }
                }
                if (
                    response?.data?.orderNumber &&
                    response?.data?.success &&
                    returnCode === '0'
                ) {
                    setPaymentSuccess(true);
                    setOrderNumber(response.data.orderNumber);
                    setEmail(response.data.email);
                    changeLanguage(response.data.language || 'fi');
                } else {
                    setPaymentSuccess(false);
                    changeLanguage(response?.data?.language || 'fi');
                }
                setInitialLoading(false);
            })
            .catch(() => {
                setPaymentSuccess(false);
                setInitialLoading(false);
            });
    }, [search, returnCode]);

    const handleDownloadClick = () => {
        const downloadUrl = `${config.EEZYPAY_API_HOST}/v1/receipt/buyer-receipt-download${search}`;
        const win = window.open(downloadUrl, '_blank');
        win?.focus();
    };

    return (
        <>
            <Header />
            <PurpleBackground>
                <ContentWrapper>
                    <ReturnPageContainer>
                        <Toast
                            style={{
                                width: isMobile() ? '96%' : '50%',
                                margin: '2%',
                            }}
                            isLarge
                        />
                        {initialLoading ? (
                            <div
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <LoadingSpinner
                                    size="30px"
                                    color={COLOR_BLUM}
                                />
                            </div>
                        ) : paymentSuccess && orderNumber ? (
                            <>
                                <img
                                    src={successImg}
                                    alt="Successful payment"
                                    height={160}
                                />
                                <ImportantText>
                                    {t('return-page.payment-successful')}
                                </ImportantText>
                                <SecondaryText>
                                    {t('return-page.receipt-sent')} {email}
                                </SecondaryText>
                                {thirdParty === 'campwire' &&
                                thirdPartyReturnUrl &&
                                thirdPartyOrderId ? (
                                    <Flex justifyCenter column>
                                        <Flex justifyCenter>
                                            <EezyButton
                                                style={{
                                                    minWidth: 275,
                                                    marginBottom: 20,
                                                }}
                                                color="purple"
                                                onClick={handleDownloadClick}
                                            >
                                                {t(
                                                    'return-page.download-receipt',
                                                )}
                                            </EezyButton>
                                        </Flex>
                                        <Flex justifyCenter>
                                            <EezyButton
                                                style={{ minWidth: 275 }}
                                                color="purple"
                                                dark
                                                onClick={() =>
                                                    redirectToCampwire(
                                                        thirdPartyReturnUrl!,
                                                        thirdPartyOrderId!,
                                                        paymentSuccess,
                                                    )
                                                }
                                            >
                                                {t(
                                                    'return-page.return-to-campwire',
                                                )}
                                            </EezyButton>
                                        </Flex>
                                    </Flex>
                                ) : (
                                    <Flex justifyCenter>
                                        <EezyButton
                                            style={{ minWidth: 275 }}
                                            color="purple"
                                            dark
                                            onClick={handleDownloadClick}
                                        >
                                            {t('return-page.download-receipt')}
                                        </EezyButton>
                                    </Flex>
                                )}
                            </>
                        ) : (
                            <>
                                <img src={errorImg} alt="Error" height={85} />
                                <ImportantText>
                                    {t('return-page.payment-failed')}
                                </ImportantText>
                            </>
                        )}
                    </ReturnPageContainer>
                </ContentWrapper>
            </PurpleBackground>
        </>
    );
};

export default ReturnPage;
