import i18n, { TOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/fi';
import Numeral from 'numeral';
import translationEn from '../locales/en/translation.json';
import translationFi from '../locales/fi/translation.json';
import { $Dictionary } from 'i18next/typescript/helpers';

export type LanguageCode = 'en' | 'fi';
export type UiLanguage = 'en' | 'fi';

interface IMomentLocales {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export const DEFAULT_LANGUAGE = 'fi';
const MOMENT_LOCALES: IMomentLocales = {
    en: moment.locale('en-gb'),
    fi: moment.locale('fi'),
};

i18n.use(LanguageDetector)
    .use(intervalPlural)
    .init({
        debug: false,
        defaultNS: 'translations',
        fallbackLng: DEFAULT_LANGUAGE,
        interpolation: {
            escapeValue: false,
        },
        lng: navigator.language,
        ns: ['translations'],
        load: 'languageOnly',

        resources: {
            en: {
                translations: translationEn,
            },
            fi: {
                translations: translationFi,
            },
        },
    });

Numeral.register('locale', 'fi', {
    abbreviations: {
        billion: 'b',
        million: 'm',
        thousand: 'k',
        trillion: 't',
    },
    currency: {
        symbol: '€',
    },
    delimiters: {
        decimal: ',',
        thousands: '\u00A0',
    },
    ordinal: () => '',
});

Numeral.locale('fi');
moment.updateLocale('en', {
    longDateFormat: {
        // @ts-expect-error ignore
        ...moment.locale('en-gb').longDateFormat,
        l: 'D/M/YYYY',
    },
});

export default i18n;

export const trans = (str: string, options?: TOptions & $Dictionary) => {
    const { t } = i18n;
    if (options) {
        return t(str, str, options) as string;
    }
    return t(str) as string;
};

export const transFixed = ({
    str,
    lang,
    options,
}: {
    str: string;
    lang?: LanguageCode;
    options: TOptions & $Dictionary & { defaultValue: string };
}) => {
    if (!lang) {
        return options ? trans(str, options) : trans(str);
    }

    return i18n.getFixedT(lang, undefined, undefined)(str, options) as string;
};

export const changeLanguage = (lang: UiLanguage) => {
    if (!(lang in MOMENT_LOCALES)) {
        lang = DEFAULT_LANGUAGE; // eslint-disable-line no-param-reassign
    }
    i18n.changeLanguage(lang);
    Numeral.locale(lang);
    moment.locale(lang);
    window.document.documentElement.lang = lang;
};

export const localDateFormat = () => {
    switch (moment.locale()) {
        case 'fi':
            return 'dd.MM.yyyy';
        default:
            return 'dd/MM/yyyy';
    }
};

export const getLanguage = () => {
    const lang = i18n.languages[0];
    return lang === 'fi' ? lang : 'en';
};
