import React from 'react';
import { Slide, ToastContainer, ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import {
    COLOR_BLACKWATER,
    COLOR_BLUM,
    COLOR_GREYS_ANATOMY,
    COLOR_IMPORTANT,
    COLOR_MEDIUM_BG,
    COLOR_STATUS_WAITING,
    COLOR_WHITE_WALKER,
} from '../styles/variables';

interface ICustomToastProps extends ToastContainerProps {
    isLarge?: boolean;
}

const StyledToast = styled(ToastContainer)`
    .eezy-toast {
        background-color: ${COLOR_GREYS_ANATOMY};
        border: 1px solid ${COLOR_MEDIUM_BG};
        border-radius: ${(props: { isLarge?: boolean }) =>
            props.isLarge ? '10px' : '100px'};
        color: ${COLOR_BLUM};
        display: flex;
        flex-direction: row-reverse;
        font-size: 15px;
        font-weight: normal;
        letter-spacing: -0.5px;
        line-height: 20px;
        min-height: ${(props: { isLarge?: boolean }) =>
            props.isLarge ? '101px' : '40px'};
        padding: 5px 15px;
        text-align: center;
    }
    .Toastify__toast--error {
        background-color: ${COLOR_IMPORTANT};
        color: ${COLOR_WHITE_WALKER};
        svg {
            color: ${COLOR_WHITE_WALKER};
        }
    }
    .Toastify__toast--warning {
        background-color: ${COLOR_STATUS_WAITING};
        color: ${COLOR_BLACKWATER};
        svg {
            color: ${COLOR_BLACKWATER};
        }
    }

    .Toastify__toast-body {
        margin-left: 10px;
        bottom: 10px !important;
    }

    &.Toastify__toast-container--bottom-center {
        width: unset;
        ${(props: { isLarge?: boolean }) =>
            props.isLarge ? 'bottom: 10px; left: 0' : 'bottom: 80px;'};
    }
`;

export const Toast = (props: ICustomToastProps) => (
    <StyledToast
        containerId={'toast'}
        autoClose={2500}
        closeOnClick={true}
        draggable
        hideProgressBar
        isLarge={props.isLarge}
        newestOnTop
        pauseOnHover
        position={props.position ? props.position : 'top-center'}
        rtl={false}
        toastClassName={'eezy-toast'}
        transition={Slide}
        {...props}
    />
);
