import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { queryHasura } from '../../utils/graphql';
import {
    COLOR_BLACK,
    COLOR_BLACKWATER,
    COLOR_BLUM,
    COLOR_GREY_FOG,
    COLOR_IMPORTANT,
    COLOR_LIGHT_BG,
    COLOR_WHITE_WALKER,
    FOCUS_COLOR,
    FOOTER_HEIGHT,
    FOOTER_HEIGHT_MOBILE,
    SCREEN_M,
    TOPBAR_HEIGHT,
} from '../../styles/variables';
import { config } from '../../config';
import { getLanguage } from '../../utils';
import { FormInput } from '../../components/form';
import { SmallP } from '../../components/textElements';
import { EezyButton, InlineButtonLink } from '../../components/Buttons';
import { Flex } from '../../components/Flex';
import LoadingSpinner from '../../components/Loading';
import { EMAIL_REGEX } from '../../utils/validation';
import Header from '../../components/Header';
import PurpleBackground from '../../components/PurpleBackground';
import Footer from '../../components/Footer';
import { FormLabel } from '../../components/form/FormLabel';
import { Icon } from '../../components/Icon';
import InfoBox from '../../components/InfoBox';
import notExistImg from '../../assets/img/not-exist.svg';
import { isMobile } from '../../utils/common';
import { Toast } from '../../components/Toast';
import TermsModal from './TermsModal';
import { TFunction } from 'i18next';

interface ISalesPage {
    creationDate: Date;
    id: number;
    occupationId: number;
    publicPath: string;
    serviceDescription?: string;
    serviceName: string;
    servicePrice: number;
    serviceVat: number;
    buyerFullName?: string;
    buyerEmail?: string;
    salesPagesUsers?: ISalesPagesUser;
}

interface ISalesPagesUser {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    language: string;
}

interface IPaymentMethodFromApi {
    currency: string[];
    group: string;
    img: string;
    imgTimestamp: number;
    maxAmount: number;
    minAmount: number;
    name: string;
    selectedValue: string;
}

interface IProps {}

interface IPaymentMethod {
    value: string;
    label: string;
    selectedValue: string;
}

const ContentWrapper = styled.div`
    width: 100%;
    display: block;
    padding-top: 40px;
    min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT_MOBILE}px);
    @media (min-width: ${SCREEN_M}px) {
        min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT}px);
    }
`;

export const SalesPageContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background-color: ${COLOR_LIGHT_BG};
    font-family: 'Euclid Circular A', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: ${COLOR_BLACK};
`;

export const SalesFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-items: center;
    padding: 30px;
    width: calc(100% - 40px);
    max-width: 644px;
    background: ${COLOR_WHITE_WALKER};
    border-radius: 10px;
    margin: 0 auto 20px auto;
`;

const NameAndServiceContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const NameWrapper = styled.div`
    flex-shrink: 0;
    align-self: center;
`;

export const PageNotExistText = styled.div`
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
    text-align: center;
`;

export const PriceContainer = styled.div`
    display: flex;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    justify-content: center;
    width: 100%;
    color: ${COLOR_BLUM};
    margin-top: 20px;
`;

export const VatContainer = styled.div`
    display: flex;
    font-size: 14px;
    justify-content: center;
    width: 100%;
    color: ${COLOR_BLUM};
    margin-bottom: 20px;
`;

export const WithButton = styled.div`
    position: relative;

    label {
        padding-right: 90px;
    }
`;

export const TypeButton = styled(InlineButtonLink)`
    position: absolute;
    right: 0;
    font-size: 13px;
    top: -5px;
`;

export const DescriptionContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const StyledField = styled(TextField).attrs(() => ({
    autoComplete: 'off',
}))`
    width: 100%;
    &&&& {
        vertical-align: baseline;
        width: 100%;

        div:first-child {
            padding-right: 0;
            padding-left: 0;
        }

        input {
            border-bottom: 1px solid;
            border-color: ${COLOR_GREY_FOG};
            box-sizing: border-box;
            color: ${COLOR_BLACKWATER};
            font-size: 15px;
            height: 40px;
            padding: 0 25px 0 0;
            text-align: left;
            width: 100%;

            &:focus {
                border-color: ${FOCUS_COLOR} !important;
            }
            &:disabled {
                border-bottom: none;
            }
        }
    }
`;

const inputStyle = {
    flexGrow: 1,
    marginBottom: 20,
    width: '100%',
};

const useStyles = makeStyles()({
    root: {
        flexGrow: 1,
        marginBottom: 20,
        width: '100% !important',

        '& .MuiInputBase-root': {
            padding: 0,
            border: 0,
            '&:before': {
                display: 'none',
            },
            '&:after': {
                display: 'none',
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
        },
    },
    listbox: {
        padding: 0,
    },
    option: {
        margin: 0,
        padding: '7px 7px',

        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: COLOR_WHITE_WALKER,
            color: COLOR_IMPORTANT,
        },
    },
});

const renderErrorMessage = (t: TFunction, errorMessageTranslation?: string) => (
    <SmallP
        color={COLOR_IMPORTANT}
        style={{ marginTop: -15, marginBottom: 15 }}
    >
        {t(errorMessageTranslation || 'sales-page.required-information')}
    </SmallP>
);

const salesPageQueryHeaders = (publicPath: string) => ({
    'X-Hasura-Public-Path': publicPath,
});
const salesPageQuery = (publicPath: string) => `
    query SalesPageData {
      sales_pages(where: { _and: {
        deleted: { _is_null: true },
        state: { _eq: 1 },
        publicPath: {_eq: "${publicPath}"},
        }}) {
        serviceDescription
        serviceName
        servicePrice
        serviceVat
        salesPagesUsers {
            firstName
            lastName
        }
        buyerFullName
        buyerEmail
      }
    }
`;

const SalesPage: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const { publicPath } = useParams();
    const [salesPage, setSalesPage] = useState<ISalesPage | undefined>(
        undefined,
    );
    const [formType, setFormType] = useState<string>('person');
    const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
    const [paymentMethodsError, setPaymentMethodsError] =
        useState<boolean>(false);
    const [fullName, setFullName] = useState<string>();
    const [fullNameError, setFullNameError] = useState<boolean>(false);
    const [companyName, setCompanyName] = useState<string>();
    const [companyNameError, setCompanyNameError] = useState<boolean>(false);
    const [companyId, setCompanyId] = useState<string>();
    const [companyIdError, setCompanyIdError] = useState<boolean>(false);
    const [email, setEmail] = useState<string>();
    const [emailError, setEmailError] = useState<boolean>(false);
    const [paymentMethodNameSelected, setPaymentMethodNameSelected] =
        useState<string>();
    const [handlePayLoading, setHandlePayLoading] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    if (
        paymentMethods.length > 0 &&
        salesPage?.servicePrice &&
        initialLoading
    ) {
        setInitialLoading(false);
    }

    useEffect(() => {
        if (publicPath) {
            queryHasura(
                salesPageQueryHeaders(publicPath),
                salesPageQuery(publicPath),
            )
                .then(
                    (result: {
                        data?: { data?: { sales_pages: ISalesPage[] } };
                    }) => {
                        const salesPageRes = result.data?.data?.sales_pages[0];
                        if (!salesPageRes) {
                            setInitialLoading(false);
                        }
                        setSalesPage(salesPageRes);
                    },
                )
                .catch(() => {
                    toast.info(t('errors.general'));
                });
        }
    }, [publicPath, t]);

    useEffect(() => {
        axios
            .get(`${config.EEZYPAY_API_HOST}/v1/pay/payment-methods`)
            .then((response: { data: IPaymentMethodFromApi[] }) => {
                const paymentMethodFormatted =
                    response.data?.map(
                        (paymentMethod: IPaymentMethodFromApi) => ({
                            label: paymentMethod.name,
                            value: paymentMethod.name,
                            selectedValue: paymentMethod.selectedValue,
                        }),
                    ) || [];
                setPaymentMethods(paymentMethodFormatted);
            });
    }, []);

    useEffect(() => {
        if (salesPage?.buyerFullName) {
            setFullName(salesPage.buyerFullName);
        }
    }, [salesPage?.buyerFullName]);
    useEffect(() => {
        if (salesPage?.buyerEmail) {
            setEmail(salesPage.buyerEmail);
        }
    }, [salesPage?.buyerEmail]);

    const initiatePayment = () => {
        const paymentMethod = paymentMethodNameSelected;
        const lastName = fullName?.split(' ').pop();
        const firstName = fullName?.split(' ').slice(0, -1)?.join(' ');
        const dataToSend = {
            publicPath,
            paymentMethod,
            firstName,
            lastName,
            email,
            companyName,
            companyId,
            lang: getLanguage(),
        };

        if (formType === 'person') {
            delete dataToSend.companyId;
            delete dataToSend.companyName;
        }

        axios
            .post(`${config.EEZYPAY_API_HOST}/v1/pay/create-charge`, dataToSend)
            .then((response: { data?: { redirectUrl?: string } }) => {
                if (response.data?.redirectUrl) {
                    window.location.href = response.data.redirectUrl;
                } else {
                    toast.info(t('errors.general'));
                    setHandlePayLoading(false);
                }
            })
            .catch(() => {
                toast.info(t('errors.general'));
                setHandlePayLoading(false);
            });
    };

    const getPaymentMethodValue = (name: string) => {
        const method = paymentMethods.find(
            (obj: IPaymentMethod) => obj.label === name,
        );
        return method?.selectedValue;
    };

    const handlePay = () => {
        const isEmailValid = email && EMAIL_REGEX.test(email);
        const isFullNameValid = fullName && fullName.split(' ').length > 1;

        if (!paymentMethodNameSelected) {
            setPaymentMethodsError(true);
        }
        if (!isFullNameValid) {
            setFullNameError(true);
        }
        if (!isFullNameValid) {
            setFullNameError(true);
        }
        if (!companyName) {
            setCompanyNameError(true);
        }
        if (!companyId) {
            setCompanyIdError(true);
        }
        if (!isEmailValid) {
            setEmailError(true);
        }

        if (
            (isFullNameValid ||
                (paymentMethodNameSelected && companyName && companyId)) &&
            isEmailValid
        ) {
            setHandlePayLoading(true);
            initiatePayment();
        }
    };

    return (
        <>
            <Header />
            <PurpleBackground>
                <ContentWrapper>
                    <SalesFormContainer>
                        <Toast
                            style={{
                                width: isMobile() ? '96%' : '50%',
                                margin: '2%',
                            }}
                            isLarge
                        />
                        {!initialLoading && !salesPage && (
                            <>
                                <img
                                    src={notExistImg}
                                    alt="Sales page does not exist"
                                    height={85}
                                />
                                <PageNotExistText>
                                    {t('sales-page.not-exist')}
                                </PageNotExistText>
                            </>
                        )}
                        {initialLoading ? (
                            <div
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <LoadingSpinner
                                    size="30px"
                                    color={COLOR_BLUM}
                                />
                            </div>
                        ) : (
                            salesPage && (
                                <>
                                    <div style={{ marginBottom: 60 }}>
                                        <NameAndServiceContainer>
                                            <NameWrapper>
                                                {
                                                    salesPage?.salesPagesUsers
                                                        ?.firstName
                                                }{' '}
                                                {
                                                    salesPage?.salesPagesUsers
                                                        ?.lastName
                                                }{' '}
                                                /
                                            </NameWrapper>
                                            <div
                                                style={{
                                                    fontWeight: 600,
                                                    marginLeft: 3,
                                                }}
                                            >
                                                {salesPage?.serviceName}
                                            </div>
                                        </NameAndServiceContainer>
                                        <PriceContainer>
                                            {salesPage &&
                                                (salesPage.servicePrice / 100)
                                                    .toFixed(2)
                                                    .replace('.', ',')}
                                            €
                                        </PriceContainer>
                                        <VatContainer>
                                            {t('sales-page.service-vat', {
                                                vat: salesPage.serviceVat,
                                            })}
                                        </VatContainer>
                                        {salesPage?.serviceDescription && (
                                            <DescriptionContainer>
                                                {salesPage?.serviceDescription}
                                            </DescriptionContainer>
                                        )}
                                    </div>
                                    <FormLabel
                                        htmlFor="select-payment-method"
                                        value={paymentMethodNameSelected}
                                        error={paymentMethodsError}
                                    >
                                        {t('sales-page.payment-method')}
                                    </FormLabel>
                                    <Autocomplete
                                        disablePortal
                                        classes={{
                                            listbox: classes.listbox,
                                            option: classes.option,
                                            root: classes.root,
                                        }}
                                        id="select-payment-method"
                                        popupIcon={
                                            <Icon
                                                icon={faChevronDown}
                                                color={COLOR_BLUM}
                                                style={{ opacity: 0.4 }}
                                            />
                                        }
                                        disableClearable
                                        options={paymentMethods}
                                        onInputChange={(e, val) => {
                                            setPaymentMethodNameSelected(
                                                getPaymentMethodValue(val),
                                            );
                                            setPaymentMethodsError(!val);
                                        }}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => (
                                            <StyledField
                                                {...params}
                                                required
                                                placeholder={t(
                                                    'sales-page.search',
                                                )}
                                                InputProps={{
                                                    ...params.InputProps,
                                                }}
                                            />
                                        )}
                                    />
                                    {paymentMethodsError &&
                                        renderErrorMessage(t)}
                                    {formType === 'person' && (
                                        <WithButton>
                                            <TypeButton
                                                onClick={() => {
                                                    setFormType('company');
                                                }}
                                                color={COLOR_BLUM}
                                            >
                                                {t('sales-page.company')}
                                            </TypeButton>
                                            {/* Full name */}
                                            <FormInput
                                                label={t(
                                                    'sales-page.full-name',
                                                )}
                                                name="fullName"
                                                placeholder={t(
                                                    'sales-page.text-here',
                                                )}
                                                onChange={(val: string) => {
                                                    setFullName(val);
                                                    setFullNameError(!val);
                                                }}
                                                style={inputStyle}
                                                value={fullName}
                                                error={fullNameError}
                                            />
                                            {fullNameError &&
                                                renderErrorMessage(
                                                    t,
                                                    'sales-page.required-full-name',
                                                )}
                                        </WithButton>
                                    )}
                                    {formType === 'company' && (
                                        <>
                                            <WithButton>
                                                <TypeButton
                                                    onClick={() => {
                                                        setFormType('person');
                                                    }}
                                                    color={COLOR_BLUM}
                                                >
                                                    {t('sales-page.person')}
                                                </TypeButton>
                                                {/* Company name */}
                                                <FormInput
                                                    label={t(
                                                        'sales-page.company-name',
                                                    )}
                                                    name="companyName"
                                                    placeholder={t(
                                                        'sales-page.text-here',
                                                    )}
                                                    onChange={(val: string) => {
                                                        setCompanyName(val);
                                                        setCompanyNameError(
                                                            !val,
                                                        );
                                                    }}
                                                    style={inputStyle}
                                                    value={companyName}
                                                    error={companyNameError}
                                                />
                                                {companyNameError &&
                                                    renderErrorMessage(t)}
                                            </WithButton>

                                            {/* Company ID */}
                                            <FormInput
                                                label={t(
                                                    'sales-page.company-id',
                                                )}
                                                name="companyId"
                                                placeholder={t(
                                                    'sales-page.text-here',
                                                )}
                                                onChange={(val: string) => {
                                                    setCompanyId(val);
                                                    setCompanyIdError(!val);
                                                }}
                                                style={inputStyle}
                                                value={companyId}
                                                error={companyIdError}
                                            />
                                            {companyIdError &&
                                                renderErrorMessage(t)}

                                            {/* Full name */}
                                            <FormInput
                                                label={t(
                                                    'sales-page.full-name',
                                                )}
                                                name="fullName"
                                                placeholder={t(
                                                    'sales-page.text-here',
                                                )}
                                                onChange={(val: string) => {
                                                    setFullName(val);
                                                    setFullNameError(!val);
                                                }}
                                                style={inputStyle}
                                                value={fullName}
                                                error={fullNameError}
                                            />
                                            {fullNameError &&
                                                renderErrorMessage(
                                                    t,
                                                    'sales-page.required-full-name',
                                                )}
                                        </>
                                    )}
                                    {/* Email */}
                                    <FormInput
                                        label={t('sales-page.email')}
                                        name="email"
                                        placeholder={t('sales-page.text-here')}
                                        onChange={(val: string) => {
                                            setEmail(val.trim());
                                            setEmailError(!val);
                                        }}
                                        style={inputStyle}
                                        value={email}
                                        error={emailError}
                                    />
                                    {emailError && renderErrorMessage(t)}
                                    {formType === 'person' ? (
                                        <InfoBox
                                            text={t(
                                                'sales-page.infobox-person',
                                            )}
                                        />
                                    ) : (
                                        <InfoBox
                                            text={t(
                                                'sales-page.infobox-company',
                                            )}
                                        />
                                    )}
                                    <InlineButtonLink
                                        style={{ marginTop: 20 }}
                                        onClick={() => {
                                            setModalOpen(true);
                                        }}
                                    >
                                        {t('sales-page.terms-button')}
                                    </InlineButtonLink>
                                    <Flex
                                        justifyCenter
                                        style={{ marginTop: 30 }}
                                    >
                                        <EezyButton
                                            style={{ width: 116 }}
                                            color="purple"
                                            dark
                                            onClick={() => {
                                                handlePay();
                                            }}
                                        >
                                            {handlePayLoading ? (
                                                <Flex
                                                    center
                                                    justifyCenter
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <LoadingSpinner
                                                        size="1em"
                                                        color={
                                                            COLOR_WHITE_WALKER
                                                        }
                                                    />
                                                </Flex>
                                            ) : (
                                                t('sales-page.pay')
                                            )}
                                        </EezyButton>
                                    </Flex>
                                </>
                            )
                        )}
                    </SalesFormContainer>
                </ContentWrapper>
            </PurpleBackground>
            <Footer />
            {modalOpen && (
                <TermsModal
                    handleModalClose={() => setModalOpen(false)}
                    isOpen={modalOpen}
                />
            )}
        </>
    );
};

export default SalesPage;
