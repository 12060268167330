import React, { CSSProperties, LabelHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import {
    COLOR_BLACKWATER,
    COLOR_BLUM_30,
    COLOR_IMPORTANT,
    COLOR_STATUS_WAITING,
} from '../../styles/variables';
import { HelpLabel } from './HelpLabel';

const Label = styled.label`
    color: ${(props) => (props.color ? props.color : COLOR_BLACKWATER)};
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.2px;

    &.hasError {
        color: ${COLOR_IMPORTANT};
    }
    &.hasWarning {
        color: ${COLOR_STATUS_WAITING};
    }
    &.disabled {
        color: ${COLOR_BLUM_30};
    }

    &:after {
        bottom: -5px;
        content: '';
        display: block;
        padding: 5px 0 0 5px;
        position: absolute;
        right: 0;

        background: linear-gradient(
            to top left,
            transparent 0%,
            transparent 50%,
            COLOR_STATUS_WAITING 50%
        );
    }
`;

interface IProps extends LabelHTMLAttributes<HTMLLabelElement> {
    children?: ReactNode;
    error?: boolean;
    disabled?: boolean;
    helpLink?: string;
    helpTexts?: string[];
    style?: CSSProperties;
    value?: string;
    warning?: boolean;
}

export const FormLabel = (props: IProps) => {
    if (!props.children) {
        return null;
    }

    const { error, disabled, value, warning, ...restLabelProps } = props;

    const className =
        (error ? 'hasError ' : '') +
        (disabled ? 'disabled ' : '') +
        (value ? 'hasValue ' : '') +
        (warning ? 'hasWarning ' : '') +
        (props.className ? props.className : '');

    const labelProps = { className, ...restLabelProps };

    const LabelElement = () => <Label {...labelProps}>{props.children}</Label>;

    if (props.helpTexts) {
        return (
            <HelpLabel
                labelComponent={LabelElement()}
                helpLink={props.helpLink}
                helpTexts={props.helpTexts}
            />
        );
    }

    return LabelElement();
};
