import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { ReactNode } from 'react';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import { SCREEN_S, SCREEN_XS } from '../styles/variables';
import { Icon } from './Icon';
import { UniqueTitle } from '../components/textElements';

const StyledModal = styled(Dialog)<{
    noScroll?: boolean;
    small?: boolean;
}>`
    & > div > div {
        ${(props) => props.noScroll && 'overflow-y: hidden;'};
        border-radius: ${(props) => (props.small ? '30px' : '30px 30px 0 0')};
        height: ${(props) => (props.small ? 'auto' : '100%')};
        margin: ${(props) => (props.small ? '20px;' : '20px 0 0 0')};
        max-height: calc(100vh - 20px);
        width: 100%;

        @media (min-width: ${SCREEN_S}px) {
            border-radius: 30px;
            height: auto;
            margin: 30px;
            max-height: calc(100vh - 120px);
            position: absolute;
            top: 30px;
        }
    }
    ${UniqueTitle} {
        font-size: ${(props) => (props.small ? '18px;' : '26px')};
        ${(props) => props.small && 'line-height: 1.4'};
        padding: ${(props) =>
            props.small ? '20px 60px 20px 20px' : '20px 60px 14px 20px'};
        text-align: left;

        @media (min-width: ${SCREEN_S}px) {
            font-size: ${(props) => (props.small ? '18px;' : '32px')};
            padding: ${(props) =>
                props.small ? '20px 60px 20px 50px' : '30px 60px 14px 50px'};
        }
    }
`;

export const ModalHeader = styled(DialogContent)`
    && {
        margin: 0;
        text-align: center;
        @media (min-width: ${SCREEN_XS}px) {
            padding: 8px 30px;
        }
    }

    &&:last-child {
        /* Not followed by ModalActions */
        margin-bottom: 30px;
    }
`;

export const ModalContent = styled(DialogContent)`
    && {
        padding: 8px 20px;

        @media (min-width: ${SCREEN_S}px) {
            padding: 8px 50px;
        }
    }

    &&:last-child {
        /* Not followed by ModalActions */
        margin-bottom: 30px;
    }
`;

export const ModalActions = styled(DialogActions)<{ small?: boolean }>`
    && {
        ${(props) =>
            !props.small && 'box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1)'};
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-height: 60px;
        padding: 20px;

        @media (min-width: ${SCREEN_XS}px) and (min-height: 500px) {
            padding: ${(props) =>
                props.small ? '17px 20px' : '14px 50px 30px 50px'};
        }

        @media (min-width: ${SCREEN_S}px) and (min-height: 500px) {
            box-shadow: none;
        }
    }
`;

interface IModalProps {
    children: ReactNode;
    id: string;
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    noScroll?: boolean;
    small?: boolean;
}

export const Modal = (props: IModalProps) => {
    const { children, id, isOpen, title, noScroll, small, ...restProps } =
        props;

    const handleClose = () => {
        props.onClose();
    };
    return (
        <StyledModal
            aria-labelledby={id}
            open={isOpen}
            noScroll={noScroll}
            small={small}
            {...restProps}
        >
            {title && (
                <UniqueTitle color="black" id={id}>
                    {title}
                    <Icon
                        icon={faTimes}
                        className="bold"
                        onClick={handleClose}
                        style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: 30,
                            top: 24,
                        }}
                    />
                </UniqueTitle>
            )}
            {children}
        </StyledModal>
    );
};
