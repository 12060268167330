import styled from 'styled-components';

export interface IFlexProps {
    baseline?: boolean;
    center?: boolean;
    column?: boolean;
    fullWidth?: boolean;
    justifyCenter?: boolean;
    posEnd?: boolean;
    relative?: boolean;
    spread?: boolean;
    posStart?: boolean;
    useReverseWrap?: boolean;
    useWrap?: boolean;
}

export const Flex = styled.div(
    { display: 'flex' },
    ({ baseline }: IFlexProps) =>
        baseline && {
            alignItems: 'baseline',
        },
    ({ center }: IFlexProps) =>
        center && {
            alignItems: 'center',
        },
    ({ column }: IFlexProps) =>
        column && {
            flexDirection: 'column',
        },
    ({ fullWidth }: IFlexProps) =>
        fullWidth && {
            width: '100%',
        },
    ({ justifyCenter }: IFlexProps) =>
        justifyCenter && {
            justifyContent: 'center',
        },
    ({ posEnd }: IFlexProps) =>
        posEnd && {
            justifyContent: 'flex-end',
        },
    ({ posStart }: IFlexProps) =>
        posStart && {
            alignItems: 'flex-start',
        },
    ({ relative }: IFlexProps) => relative && { position: 'relative' },
    ({ spread }: IFlexProps) =>
        spread && {
            justifyContent: 'space-between',
        },
    ({ useReverseWrap }: IFlexProps) =>
        useReverseWrap && {
            flexWrap: 'wrap-reverse',
        },
    ({ useWrap }: IFlexProps) =>
        useWrap && {
            flexWrap: 'wrap',
        },
);
