import { env } from './utils/env';

interface IConfig {
    ENV: string;
    EEZYPAY_HASURA_HOST: string;
    EEZYPAY_API_HOST: string;
}

export const config: IConfig = {
    ENV: env('REACT_APP_ENV', 'dev'),
    EEZYPAY_HASURA_HOST: env('REACT_APP_EEZYPAY_HASURA_HOST', 'http://localhost:8090'),
    EEZYPAY_API_HOST: env('REACT_APP_EEZYPAY_API_HOST', 'http://localhost:8000'),
};
