import axios from 'axios';
import { config } from '../config';
import { hasuraPath } from '../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const queryHasura = (headers: any, query: string) =>
    axios({
        baseURL: config.EEZYPAY_HASURA_HOST,
        headers,
        url: hasuraPath,
        method: 'post',
        data: {
            query,
        },
    });
