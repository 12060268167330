import { CSSProperties } from 'react';
import styled from 'styled-components';
import { SCREEN_S } from '../../styles/variables';
import { Flex, IFlexProps } from '../Flex';

export { FormInput, Input } from './FormInput';

export interface IGeneralFormInputProps {
    color?: string;
    disabled?: boolean;
    error?: boolean;
    helpLink?: string;
    helpTexts?: string[];
    label?: string;
    name: string;
    readonly?: boolean;
    required?: boolean;
    style?: CSSProperties;
    warning?: boolean;
}

export const FormSection = styled(Flex).attrs(() => ({
    column: true,
}))`
    margin-top: 15px;
    & ~ & {
        margin-top: 45px;
    }
`;

export const SplitRow = styled(Flex).attrs(() => ({
    center: true,
    spread: true,
}))`
    & > div:not(:nth-child(1)) {
        margin-left: ${(props: IFlexProps) => (props.useWrap ? 0 : 10)}px;
        margin-top: 0;
        flex-grow: 1;
    }

    & ~ & {
        margin-top: 15px;
    }
`;

export const StackableRow = styled(Flex).attrs(() => ({
    column: true,
}))`
    @media (min-width: ${SCREEN_S}px) {
        flex-direction: row;
    }
`;

export const FormRow = styled(Flex).attrs(() => ({
    column: true,
}))`
    & ~ &,
    & ~ ${SplitRow}, ${SplitRow} ~ & {
        margin-top: 15px;
    }
`;
